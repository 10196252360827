import React, {useRef} from "react";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import Folder, {FolderTypeEnum} from "../../domain/Folder";
import {AppState} from "../../AppState";
import "./FolderTreeView.scss";
import {FolderActions} from "../../actions/FolderActions";
import classNames from "classnames";
import {FolderLocation} from "../../locations/FolderLocation";
import {Collapse, List, ListItem, ListItemText} from "@mui/material";
import ExpandMore from '@mui/icons-material/ExpandMore';
import {canCreateSubFolder, FolderContextMenu} from './FolderContextMenu';
import {EditFolderDialog} from './EditFolderDialog';
import {EmailActions} from '../../actions/EmailActions';
import {SystemActions} from "../../actions/SystemActions";
import {DB} from "../../db/DbManager";
import {isSpecialFolder} from "../../reducers/SyncHelper";
import FolderIcon from '@mui/icons-material/Folder';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import InboxIcon from '@mui/icons-material/Inbox';
import DeleteIcon from '@mui/icons-material/Delete';
import OutboxIcon from '@mui/icons-material/Outbox';
import SentItemsFolderIcon from '@mui/icons-material/Send';
import DraftFolderIcon from '@mui/icons-material/DriveFileRenameOutline';
import ArchiveIcon from '@mui/icons-material/AccessTime';
import DLQIcon from '@mui/icons-material/SdCardAlert';
import GreylistFolderIcon from '@mui/icons-material/PauseCircleFilled';

interface DisplayFolder extends Folder {
    children: DisplayFolder[];
    childUnreadCount: number;
}

function constructFolderTree(folders: Folder[], draftEmailCount: number): DisplayFolder[] {
    const root: DisplayFolder[] = [];
    const folderMap = new Map<string, DisplayFolder>();

    for (const folder of folders) {
        folderMap.set(folder.serverId,
            {
                ...folder,
                children: [],
                unreadCount: folder.type === FolderTypeEnum.Drafts ? draftEmailCount : folder.unreadCount,
                childUnreadCount: 0,
            });
    }

    folderMap.forEach(entry => {
        const folder = folderMap.get(entry.parentId);
        if (folder) {
            folder.children.push(entry);
            folder.childUnreadCount += entry.unreadCount + entry.childUnreadCount;
        } else {
            root.push(entry);
        }
    });

    sortFolderTree(root, 0);

    return root;
}

function sortFolderTree(folders: DisplayFolder[], level: number) {
    folders.sort((a, b) => a.name > b.name ? 1 : -1);

    for (const folder of folders) {
        sortFolderTree(folder.children, level + 1);
        folder.indent = level;
    }
}

function getFolderIcon(icon: string) {
    switch (icon) {
        case "Inbox":
            return <InboxIcon/>;
        case "Deleted":
            return <FolderDeleteIcon/>;
        case "Junk":
            return <DeleteIcon/>;
        case "Outbox":
            return <OutboxIcon/>;
        case "SentItems":
            return <SentItemsFolderIcon/>;
        case "Drafts":
            return <DraftFolderIcon/>;
        case "Archive":
            return <ArchiveIcon/>;
        case "DLQ":
            return <DLQIcon/>;
        case "Greylist":
            return <GreylistFolderIcon/>;
    }
    return <FolderIcon/>;
}

const RenderFolderTree = ({
                              folderList,
                              draftEmailCount,
                              outgoingEmails,
                              selectedFolderId,
                              expandedFolderIds,
                              contextFolder,
                              folderSelectionMode,
                              editingFolder,
                              emailsToMove,
                              onExpandFolder,
                              onSetContextFolder,
                              onClearContextFolder,
                              onCompleteMoveFolder,
                              onCompleteMoveEmails,
                              onCancelMoveFolder,
                          }: FolderTreeViewProps) => {

    const menuRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const folderTree = constructFolderTree(folderList, draftEmailCount);

    const isPrivateBrowserMode = DB.isPrivateBrowserMode();

    function showFolder(folder: DisplayFolder) {
        return !folder.hidden;
    }

    function isFolderSyncedOrValidTarget(folder: DisplayFolder) {
        if (folderSelectionMode === "email") {
            return folder.type === FolderTypeEnum.UserMail || folder.type === FolderTypeEnum.Inbox || folder.type === FolderTypeEnum.Generic;
        }
        if (folderSelectionMode === "folder") {
            return canCreateSubFolder(folder.type) && folder.serverId !== editingFolder.serverId;
        }
        return true;
    }

    function handleSelectFolder(e: any, folder: DisplayFolder) {
        if (folderSelectionMode === "email") {
            if (isFolderSyncedOrValidTarget(folder)) {
                onCompleteMoveEmails(emailsToMove, folder.serverId);
                onCancelMoveFolder();
            }
        } else if (folderSelectionMode === "folder") {
            if (isFolderSyncedOrValidTarget(folder)) {
                onCompleteMoveFolder(editingFolder, folder.serverId);
                onCancelMoveFolder();
            }
        } else {
            FolderLocation.selectFolder(folder);
        }
        dispatch(SystemActions.showFolderMenu(false));
        e.stopPropagation();
    }

    function createFolderDiv(folder: DisplayFolder) {
        const expanded = !!folderSelectionMode || expandedFolderIds.includes(folder.folderId);

        const childFolderElements = folder.children.length > 0
            ? (
                <Collapse in={expanded} timeout="auto">
                    {folder.children
                        .filter(showFolder)
                        .map(child => createFolderDiv(child))
                    }
                </Collapse>)
            : null;

        const folderClasses = classNames("folder", "selectable",
            {
                "selected": folder.folderId === selectedFolderId,
                "synced": isFolderSyncedOrValidTarget(folder) || isPrivateBrowserMode,
                "context-selected": folder.folderId === contextFolder?.folderId
            },
        );

        const onExpand = (e: any) => {
            onExpandFolder(folder, !expanded);
            e.stopPropagation();
        };

        const folderCountBadge =
            folder.type === FolderTypeEnum.Outbox
                ? outgoingEmails.length
                : folder.unreadCount;

        return (
            <List component={folder.indent === 0 ? "nav" : "div"}
                  key={folder.folderId}
                  onClick={(e: any) => handleSelectFolder(e, folder)}
                  dense={true}
                  disablePadding={true}>
                <ListItem button className={folderClasses} onContextMenu={() => onSetContextFolder(folder)}
                          style={{paddingLeft: ((folder.indent + 1) * 16) + "px", transition: "none"}}>
                    <span className="folder-type">
                        {getFolderIcon(folder.icon)}
                    </span>

                    <ListItemText primary={<>
                        <span>{folder.name}</span>
                        {expanded && <span className="unread-count">{folderCountBadge || null}</span>}
                        {!expanded && <span className="unread-count">{(folderCountBadge + folder.childUnreadCount) || null}</span>}
                    </>}/>

                    {folder.children.length > 0 && (
                        <div className={classNames("selectable-2 expander")}>
                            <ExpandMore onClick={onExpand} className={classNames({"expanded": expanded})}/>
                        </div>
                    )}
                </ListItem>
                {childFolderElements}
            </List>
        );
    }

    return (
        <>
            <div className="folder-tree" ref={menuRef}>
                {folderTree
                    .filter(showFolder)
                    .map(createFolderDiv)
                }
            </div>
            <FolderContextMenu elementRef={menuRef} onClose={() => onClearContextFolder()}/>

            <EditFolderDialog/>
        </>
    );
};

const mapStateToProps = ({folderState, itemState}: AppState) => ({
    folderList: folderState.folderList.filter(f => !isSpecialFolder(f)),
    outgoingEmails: folderState.outgoingEmails,
    expandedFolderIds: folderState.expandedFolderIds,
    selectedFolderId: folderState.selectedFolderId,
    folderSelectionMode: folderState.folderSelectionMode,
    contextFolder: folderState.contextFolder,
    editingFolder: folderState.editingFolder,
    emailsToMove: folderState.emailsToMove,

    draftEmailCount: itemState.draftEmailHeaders.length,
});

const mapDispatch = {
    onExpandFolder: (folder: Folder, expand: boolean) => FolderActions.expandFolder(folder.folderId, expand),

    onSetContextFolder: (folder: Folder) => FolderActions.setContextFolder(folder),

    onClearContextFolder: () => FolderActions.setContextFolder(undefined),

    onCompleteMoveEmails: EmailActions.completeMoveEmails,
    onCompleteMoveFolder: FolderActions.completeMoveFolder,
    onCancelMoveFolder: FolderActions.cancelMoveFolder,
};

const connector = connect(mapStateToProps, mapDispatch);

type FolderTreeViewProps = ConnectedProps<typeof connector>;

const FolderTreeView = connector(RenderFolderTree);

export default FolderTreeView;
