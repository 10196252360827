import {EntityDto} from "./EntityDto";
import {ItemReminderDto} from "../../domain/ItemReminderDto";
import {calculateDateRangeCategory} from "../../util/DateHelper";

export default interface EmailHeaderDto extends EntityDto {
    FolderId: number;
    ServerId?: string;  // Only for greylisted emails
    LocalId?: string;
    Read: number;
    Flagged: number;
    Pinned?: boolean;
    From: string;
    To: string | undefined;
    CC: string | undefined;
    Subject: string;
    AttachmentCount: number;
    LastVerb: string | null;
    PendingSecureCount: number | null;
    Category: string | null;
    Date: number;
    Defaulted: boolean;
    Importance: string | null;
    ThreadId: string | null;
    CertificateId: number | null;
    SpfMatchStatus: string;
    SmimeStatus: string;
    PgpStatus: string;
    DomainKeyMatchStatus: string;
    SendCount: number | null;
    MessageClass: string;
    BodySample: string;
    ShowTrustShield: boolean;

    IsDraft?: boolean;

    Reminder: ItemReminderDto | undefined;

    DisplayDate: number | undefined;
}

export interface EmailHeader extends EmailHeaderDto {
    DisplayFrom?: string;
    IsDummyLoadingRow: boolean;
    DateRangeCategory: string;
}

export function convertEmailHeader(header: EmailHeaderDto): EmailHeader {
    return {
        ...header,
        IsDummyLoadingRow: false,
        DateRangeCategory: header.Pinned ? "Pinned" : calculateDateRangeCategory(header.DisplayDate ?? header.Date),
    };
}
