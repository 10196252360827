import {locationManager} from "../BootStrap";
import {LocationProperties} from "../util/LocationParser";
import {Dispatch} from "redux";
import {dispatchRestCall, performFetch} from "../util/HttpHelper";
import {EmailContentDto, EmailMetaDataResponseDto, EmailThreadIdsDto, FullEmailContent, FullEmailThreadContent} from "../domain/EmailContentDto";
import {ComposeEmailActions} from "../actions/ComposeEmailActions";
import {uuid} from "../util/Uuid";
import {EmailHeader} from "../services/messages/EmailHeaderDto";
import {EmailActions} from "../actions/EmailActions";
import {EmailResponseFormatter, EmailResponseType} from "../formatters/EmailResponseFormatter";
import DbDraftEmail from "../domain/DbDraftEmail";
import {isEmailContent, ItemContent} from "../domain/ItemContent";
import {dispatchPromise} from "../util/TypeHelper";
import {AppState} from "../AppState";
import {ANALYZE_EMAIL_ASYNC, AnalyzeEmailAsyncAction, GET_EMAIL_CONTENT_ASYNC, GET_EMAIL_SOURCE_ASYNC, GetEmailContentAsyncAction, GetEmailSourceAsyncAction,} from '../actions/EmailActionTypes';
import {AnalyzeEmailRequestDto} from '../services/messages/requests/AnalyzeEmailRequestDto';
import {EmailAnalysisDto} from '../services/messages/responses/EmailAnalysisDto';
import {EmailSourceDto} from '../domain/EmailSourceDto';
import {EmailSourceRequestDto} from '../services/messages/requests/EmailSourceRequestDto';
import {PreferPlainTextEmailsPref} from "../util/Preferences";
import {AllActionTypes} from "../actions/AllActionTypes";
import {SystemActions} from "../actions/SystemActions";
import {parseIntSafe} from "../util/Formatters";
import {createAppProperties} from "./LocationUtil";
import {handleViewEncryptedEmailContent, isEncryptedEmailContent, isSignedEmailContent} from "../security/EncryptedEmailProcessor";
import {DB} from "../db/DbManager";
import {AppDispatch} from "../AppStore";
import {noop} from "rxjs";
import {ServiceWorkerMessage} from "../domain/ServiceWorkerMessaging";

export interface EmailLocationProperties {
    composeId?: string;
    mailto?: string;
}

export const EmailLocationPropertyNames = createAppProperties<EmailLocationProperties>("composeId", "mailto");

export class EmailLocation {

    static async respondToEmail(dispatch: AppDispatch, itemContent: ItemContent, responseType: EmailResponseType): Promise<DbDraftEmail> {

        // TODO: check it is email content!!
        const emailThread = itemContent as FullEmailThreadContent;
        const selectedEmail = emailThread.Emails.find(e => e.Id === emailThread.Id)!;

        const draftEmail = await new EmailResponseFormatter().respondToEmail(selectedEmail, responseType);

        const unreadEmailIds = emailThread.Emails.filter(e => !e.Read).map(e => e.Id);
        if (unreadEmailIds.length > 0) {
            await EmailActions.markEmailIdsAsRead(dispatch, unreadEmailIds, true);
        }

        locationManager.updateWindowLocation("email", {composeId: draftEmail.Uid});

        return draftEmail;
    }

    static composeNewEmail() {
        locationManager.updateWindowLocation("email", {composeId: uuid()});
    }

    static openDraftEmail(emailHeader: EmailHeader) {
        if (emailHeader.IsDraft) {
            locationManager.updateWindowLocation("email", {composeId: emailHeader.LocalId});
        }
    }

    static openEmailById(emailId: number, emailFolderId: number) {
        locationManager.updateWindowLocation("email", {itemId: emailId, itemType: "email", folderId: emailFolderId});
    }

    static openDraftEmailByUid(uid: string) {
        locationManager.updateWindowLocation("email", {composeId: uid});
    }

    static stopComposingEmail() {
        locationManager.modifyWindowLocation("email", location => delete location.composeId);
    }

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties) {

        const state = getState();

        handleEmailComposition(state, newLocation, dispatch);
    }

    static handleOpenWindowMessage(dispatch: AppDispatch, data: ServiceWorkerMessage) {
        const emailId = parseIntSafe(data.emailId);

        if (data.action === "reply" && emailId) {
            console.log("Replying to email ", emailId);

            handleViewEmail(emailId, dispatch, itemContent => EmailLocation.respondToEmail(dispatch, itemContent, "Reply"));
            return;
        }

        const folderId = parseIntSafe(data.folderId);
        locationManager.updateWindowLocation("email", {folderId, itemId: emailId, itemType: "email"});
    }
}

export function markSelectedEmailAsRead(dispatch: AppDispatch, selectedEmailHeaders: EmailHeader[]) {
    if (selectedEmailHeaders.length !== 1 || selectedEmailHeaders[0].Read || selectedEmailHeaders[0].IsDraft) {
        return;
    }
    EmailActions.markEmailIdsAsRead(dispatch, [selectedEmailHeaders[0].Id], true)
        .then(noop, () => dispatch(SystemActions.showError("Unable to mark email as read")));
}

async function getEmailIdsInThread(emailId: number): Promise<number[]> {
    try {
        const response = await performFetch(`/Mail3/EmailIdsInThread/${emailId}`) as EmailThreadIdsDto;
        return response.Ids;
    } catch (e) {
        console.error("Unable to fetch the email ids in the thread", e);
    }
    return [emailId];
}

async function getEmailContents(emailIds: number[]) {
    const textPartId = PreferPlainTextEmailsPref.get() ? -1 : null;

    const emailContentsMap = textPartId == null ? await DB.getEmailContents(emailIds) : new Map<number, EmailContentDto>();
    const emailContentsToFetch: number[] = [];
    for (const emailId of emailIds) {
        if (!emailContentsMap.has(emailId)) {
            emailContentsToFetch.push(emailId);
        }
    }

    if (emailContentsToFetch.length > 0) {
        const fetchedEmailContents = await performFetch(`/Mail3/FetchEmailContents?${textPartId ? "textPartId=" + textPartId : ""}`, emailContentsToFetch) as EmailContentDto[];
        for (const emailContent of fetchedEmailContents) {
            emailContentsMap.set(emailContent.Id, emailContent);
            if (textPartId == null) {
                await DB.saveEmailContent(emailContent);
            }
        }
    }

    return emailContentsMap;
}

async function getEmailMetaDatas(emailContentsMap: Map<number, EmailContentDto>): Promise<EmailMetaDataResponseDto> {

    const emailReadLogsToFetch: number[] = [];
    for (const emailContent of emailContentsMap.values()) {
        emailReadLogsToFetch.push(emailContent.Id);
    }

    if (emailReadLogsToFetch.length > 0) {
        try {
            return await performFetch(`/Mail3/FetchEmailMetaDatas`, emailReadLogsToFetch) as EmailMetaDataResponseDto;
        } catch (e) {
            console.log("Unable to fetch read logs");
        }
    }
    return {};
}

export async function handleViewEmail(newEmailId: number, dispatch: AppDispatch, callback?: (content: FullEmailThreadContent) => void) {

    dispatch({type: GET_EMAIL_CONTENT_ASYNC, emailId: newEmailId});
    try {
        const emailIds = !callback
            ? await getEmailIdsInThread(newEmailId)
            : [newEmailId];

        const emailContentsMap = await getEmailContents(emailIds);

        const emailHeaders = await DB.getEmailHeaders(emailIds);

        const emailMetaDatas: EmailMetaDataResponseDto = await getEmailMetaDatas(emailContentsMap);

        const fullEmailContents: FullEmailContent[] = [];
        for (const emailId of emailIds) {
            const emailContent = emailContentsMap.get(emailId);
            const emailHeader = emailHeaders.get(emailId);
            if (emailHeader && emailContent) {
                const metaData = emailMetaDatas[emailId];
                const readLogs = metaData?.ReadLogs ?? [];
                const responses = metaData?.Responses ?? [];

                const fullEmailContent: FullEmailContent = {
                    ...emailContent,
                    ...emailHeader,
                    ReadLogs: readLogs,
                    Responses: responses,
                };
                fullEmailContents.push(fullEmailContent);
            }
        }

        const emailThreadContents: FullEmailThreadContent = {
            Id: newEmailId,
            Emails: fullEmailContents,
        };
        if (callback && isEmailContent(emailThreadContents)) {
            callback(emailThreadContents);
            dispatch({type: GET_EMAIL_CONTENT_ASYNC});
        } else if (isEmailContent(emailThreadContents)) {
            let needsDecrypting = false;
            for (const content of emailThreadContents.Emails) {
                content.IsEncrypted = content.IsEncrypted || isEncryptedEmailContent(content);
                content.IsSigned = content.IsSigned || isSignedEmailContent(content);
                if (content.IsEncrypted || content.IsSigned) {
                    // TODO: check encrypted emails
                    needsDecrypting = true;
                }
            }

            if (needsDecrypting) {
                handleViewEncryptedEmailContent(emailThreadContents, dispatch)
                    .catch((error: Error) => ({type: GET_EMAIL_CONTENT_ASYNC, error}));
            } else {
                const action: GetEmailContentAsyncAction = {type: GET_EMAIL_CONTENT_ASYNC, content: emailThreadContents};
                dispatch(action);
            }
        }
    } catch (error) {
        dispatch({type: GET_EMAIL_CONTENT_ASYNC, error});
    }
}

export function handleAnalyzeEmail(newEmailId: number, dispatch: Dispatch<AllActionTypes>) {
    const request: AnalyzeEmailRequestDto = {EmailId: newEmailId};

    const beginAction: AnalyzeEmailAsyncAction = {type: ANALYZE_EMAIL_ASYNC, emailId: newEmailId};

    dispatchRestCall(dispatch, "AnalyzeEmail", request,
        () => beginAction,
        (analysis: EmailAnalysisDto) => ({type: ANALYZE_EMAIL_ASYNC, analysis}),
        (error: Error) => ({type: ANALYZE_EMAIL_ASYNC, error}));
}

export function handleGetEmailSource(emailId: number, emailUid: string | undefined, outboxItem: boolean, decoded: boolean, dispatch: Dispatch<AllActionTypes>) {
    const request: EmailSourceRequestDto = {
        EmailId: emailId,
        Decoded: decoded,
        EmailUid: emailUid,
        OutboxItem: outboxItem
    };

    const beginAction: GetEmailSourceAsyncAction = {type: GET_EMAIL_SOURCE_ASYNC, request};

    dispatchRestCall(dispatch, "GetEmailSource", request,
        () => beginAction,
        (emailSource: EmailSourceDto) => ({type: GET_EMAIL_SOURCE_ASYNC, emailSource}),
        (error: Error) => ({type: GET_EMAIL_SOURCE_ASYNC, error}));
}

function handleEmailComposition(state: AppState, newLocation: LocationProperties, dispatch: Dispatch<AllActionTypes>) {
    if (!DB.isInitialised()) return;

    const {compose} = state;
    const {draftEmail, isComposingEmail} = compose;

    const newComposeId = newLocation.composeId;

    if ((!!draftEmail) !== (!!newComposeId) || (!isComposingEmail && newComposeId) || (draftEmail && draftEmail?.Uid !== newComposeId)) {

        if (newComposeId) {
            console.log("Compose new email: ", draftEmail?.Uid, newComposeId);
            dispatchPromise(dispatch, ComposeEmailActions.composeEmail(newComposeId));

        } else if (isComposingEmail) {
            console.log("Compose location change: ", draftEmail?.Uid, newComposeId);
            ComposeEmailActions.hideEmailComposer(compose.draftEmail, dispatch)
                .catch(e => dispatch(SystemActions.showFormattedError("Unable to close composer", e)));
        }
    }
}
